import React from 'react'
import { Link } from 'gatsby'
import { Flex, Button, Heading, Text } from 'theme-ui'
import { FaRegHandshake } from 'react-icons/fa'

const styles = {
  wrapper: {
    alignItems: `center`,
    flexDirection: `column`,
    bg: `omegaLighter`,
    borderRadius: `lg`,
    size: `full`,
    p: 4
  },
  heading: {
    color: `omegaDark`,
    wordSpacing: 500, //force line breaks
    svg: {
      color: `beta`,
      size: `icon.lg`,
      display: `block`,
      mb: 3
    }
  },
  subheading: {
    color: `omegaDark`,
    fontWeight: `normal`
  },
  list: {
    color: `omegaDark`,
    listStyle: `none`,
    m: 0,
    p: 0,
    li: {
      p: 0,
      my: 2
    },
    'li:before': {
      content: `""`,
      display: `inline-block`,
      width: `icon.xs`,
      bg: `success`,
      borderRadius: `full`,
      size: `7px`,
      mr: `7px`
    }
  },
  button: {
    display: `block`,
    mt: `auto`
  }
}

const BannerVertical = () => (
  <Flex sx={styles.wrapper}>
    <Heading variant='h2' sx={styles.heading}>
      <FaRegHandshake />À propos
    </Heading>
    <Heading variant='h4' sx={styles.subheading}>
      👋 l'objectif principal de ce site est de partager les pratiques les plus
      populaires dans l’industrie du logiciel. Plusieurs points forts à noter :
    </Heading>
    <Text as='ul' variant='small' sx={styles.list}>
      <li>Un contenu simple à apprendre et surtout à appliquer 📙</li>
      <li>Tous les artciles sont rédigés en Markdown 😉</li>
      <li>Trés fluide 🚀</li>
      <li>Dark mode 💻 </li>
    </Text>

    <Text variant='small'>
      <p>
        Si vous êtes passionnés comme moi, et que vous souhaitez partager vos
        meilleures pratiques n'hésitez pas à me contacter, je serai heureux de
        vous y donner accès 😊.
      </p>
    </Text>
    <Button
      variant='primary'
      as={Link}
      to='/about'
      sx={styles.button}
      aria-label='Learn More'
    >
      En savoir plus
    </Button>
  </Flex>
)

export default BannerVertical
